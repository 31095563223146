<template>
  <div class="plan">
    <el-form :inline="true" class="form-content">
      <el-form-item label="方案名称">
        <el-input placeholder="输入关键字" v-model="planName" class="wd200"></el-input>
      </el-form-item>
      <el-form-item label="创建人">
        <el-select placeholder="请选择创建人" v-model="planCreator" clearable style="width:200px;">
          <el-option
            v-for="(item,index) in userList"
            :key="index"
            :value="item.organBusiness.id"
            :label="item.organBusiness.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="dateTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" class="ml20" @click="getDataList">查询</el-button>
        <el-button class="ml20" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="search-list">
      <el-button type="primary" @click="addNewPlan">添加</el-button>
    </div>
    <div class="table">
      <el-table
        :data="dataList"
        style="width: 100%"
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
      >
        <el-table-column type="index" width="50"></el-table-column>
        <!-- <el-table-column prop='' label='方案类型'  > </el-table-column> -->
        <el-table-column prop="programName" label="方案名称"></el-table-column>
        <el-table-column prop="executionDay" label="持续时间"></el-table-column>
        <el-table-column prop="number" label="分配人数"></el-table-column>
        <!-- <el-table-column prop='disease' label='对应病种'  > </el-table-column> -->
        <el-table-column prop="creator" label="创建人"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column label="操作" fixed="right" align="right" header-align="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="goToInfo(scope.row)">详情</el-button>
            <el-button type="text" size="small" @click="goToEdit(scope.row)">修改</el-button>
            <el-button type="text" size="small" @click="del(scope.row)" style="color:#f56c6c">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "plan",
  data() {
    return {
      planName: "", //方案名称
      planCreator: "", //创建人
      // creatorList:[],
      dateTime: [], //创建时间
      // planItem:'',//方案事项
      // planType:'',//方案类型
      // planItemList:[],//方案事项列表
      // planTypeList:[],//方案类型列表
      dataList: [{}],
      dataListLoading: true, //列表loading
      dataListSelections: [], //选中的列
      pageNo: 1,
      pageSize: 10,
      totalPage: 0, //总页数
      userList: [], //创建人列表
      diseasesList: [],
    };
  },
  async mounted() {
    await this.getUserList();
    await this.getDiseasesList();
    await this.getDataList();
  },
  methods: {
    async getDataList() {
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      const user = JSON.parse(localStorage.getItem("userInfo"));
      const { data: res } = await this.$http({
        url: this.$http.adornUrl("/programInfo/list"),
        method: "post",
        data: {
          programName: this.planName,
          teamsId: teamsId,
          // deptId:enertyId,
          // hospitalId:user.deptPid,
          // deptId:user.deptId,
          pageSize: this.pageSize,
          pageNo: this.pageNo,
          createBy: this.planCreator,
          startTime: this.dateTime[0],
          endTime: this.dateTime[1],
        },
      });
      // console.log(res,'res')
      res.data.forEach((v) => {
        this.userList.forEach((user) => {
          if (v.createBy == user.organBusiness.id) {
            v.creator = user.organBusiness.name;
          }
        });
        this.diseasesList.forEach((v2) => {
          if (v.enertyId == v2.value) {
            v.disease = v2.name;
          }
        });
      });
      this.dataList = res.data;
      this.dataListLoading = false;
      this.totalPage = res.totalCount;

      // this=
    },
    async getDiseasesList() {
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list"),
        method: "post",
		data: {
		  stringParam1: '基础病种',
		
		},
       
      });
      // console.log(res,'res')
      this.diseasesList = res.data;
    },
    async getUserList() {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/organBusiness/backUserList"),
        method: "post",
        data: {
          // hospitalId:user.deptPid,
          intParam1: 999,
        },
      });
      console.log(res, "user res");
      this.userList = res.data;
    },
    async del(row) {
      // console.log(row,'row');
      const id = row.id;
      this.$confirm("确定进行[删除方案]操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const ids = [];
          // this.dataListSelections.forEach(v=>{
          //   ids.push(v.id)
          // })
          // const id = ids.join(`,`)

          const { data: res } = await this.$httpAes({
            url: this.$httpAes.adornUrl(`/programInfo/delete?ids=${id}`),
            method: "post",
			data:{
				ids:id
			}
          });
          console.log(res);
          if (res.status) {
            this.$message.success("删除方案成功");
            this.getDataList();
          }
        })
        .catch(() => {});
    },
    goToInfo(row) {
      this.$router.push({ path: "/zykgl/plan/info", query: { id: row.id } });
    },
    goToEdit(row) {
      console.log(row.id, "row id");
      this.$router.push({
        path: "/zykgl/plan/edit",
        query: { id: row.id, edit: true },
      });
    },
    addNewPlan() {
      this.$router.push("/zykgl/plan/add");
    },
    reset() {
      this.planName = "";
      this.planCreator = "";
      this.dateTime = [];
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.plan {
  .search-list {
    display: flex;
    align-items: center;
    .label {
      width: 5%;
    }
  }
}
</style>